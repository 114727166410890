export const admin_uids = [
  // {
  //   uid: "sRBZERWE5fZqlULQYFX14g3AV4h1",
  //   email: "tukadiyar9@gmail.com",
  // },
  // {
  //   uid: "gzqcINYp7qTTZRA5V8ulIHgkTN72",
  //   email: "raju.2kadya@gmail.com",
  // },
  // {
  //   uid: "n7H7rjBAlYVkz31JNzSGdCKEbGl1",
  //   email: "ary7sharma@gmail.com",
  // },
  // {
  //   uid: "756v7teZcvWHuG9GAW10MEct43A2",
  //   email: "imadityaanand24@gmail.com",
  // },
  {
    uid: "4lE8qtdxeDMu0UpzizOi20h4kEV2",
    email: "tukadiyar9@gmail.com",
  },
  {
    uid: "SUYIoyiIPyYKQlPXvCMcMPKLa6o1",
    email: "connect@storygiri.com",
  },
];
